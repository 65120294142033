import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import { Table } from '../components/table';
import typeFixesGraph from '../images/type-fixes-over-time.png';
import totalTypeErrorsImg from '../images/total-type-errors.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Fixing over 400 TypeScript errors`}</h1>
    <p>{`I contribute to a React client written mostly in TypeScript.`}</p>
    <p>{`Unfortunately, when I joined the team there were over 400 type errors in this code repository.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={totalTypeErrorsImg} alt="Found 408 errors." width="300px" />
    </div>
    <p>{`I spent some time thinking about our options to eliminate these errors.`}</p>
    <ol>
      <li parentName="ol">{`We could create Jira tickets up front and prioritize the tech debt pay down with our Product team.`}</li>
      <li parentName="ol">{`We could configure a separate TypeScript build that only includes files with no type errors, add files to the list as we fix errors and add the error-free build step to CI.`}</li>
      <li parentName="ol">{`We could have our cake and eat it too.`}</li>
    </ol>
    <h2><HashLink id="jira" to="/typescript-errors#jira" mdxType="HashLink">{`Create Jira tickets and prioritize`}</HashLink></h2>
    <p>{`The problem with this approach is the `}<em parentName="p">{`time`}</em>{` it would take.`}</p>
    <p>{`Engineers would have investigate the codebase to identify a logical ticket breakdown. Then we would have to create tickets. Then we would have to groom the tickets. Then we would have to point the tickets. Then we would have to prioritize the tickets.`}</p>
    <p>{`I'll say it... most of the time, fixing bugs and building features gets priority over addressing tech debt. That's just the way the cookie crumbles.`}</p>
    <p>{`What's more, we already had technical debt tickets which were yet to be brought into a sprint. These were higher priority than any type fix tickets we would create.`}</p>
    <p>{`Even if this new work was top priority, that's a lot of time.`}</p>
    <h2><HashLink id="build" to="/typescript-errors#build" mdxType="HashLink">{`Configure a separate TypeScript build for CI`}</HashLink></h2>
    <p>{`The inspiration for this strategy came from a couple resources my teammate shared. I was hoping to link the resources here. Unfortunately, I gave it the ole college try but couldn't find them.`}</p>
    <p>{`Advantages:`}</p>
    <ul>
      <li parentName="ul">{`Incrementally fix all the errors without taking a lot of time away from product enhancements.`}</li>
      <li parentName="ul">{`Ensure no new type errors make it into passing files.`}</li>
      <li parentName="ul">{`No need to create a bunch of tickets to prioritize.`}
        <ul parentName="li">
          <li parentName="ul">{`Some tickets would still need to be created for large efforts but much less than option #1 above.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Disadvantages:`}</p>
    <ul>
      <li parentName="ul">{`This would introduce overhead when adding, deleting and re-organizing files.`}</li>
      <li parentName="ul">{`Team would need discipline to recognize when files had type errors and to fix the errors.`}
        <ul parentName="li">
          <li parentName="ul">{`Not saying that we weren't a disciplined crew. We definitely could have come to an agreement and trusted each other to do our best. But I `}<em parentName="li">{`am`}</em>{` saying the last thing we needed was something else to manually keep track of.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`We would need to maintain two build configs.`}
        <ul parentName="li">
          <li parentName="ul">{`The error-free config would need to be a complete dependency graph of files without type errors in order for the build to pass.`}</li>
        </ul>
      </li>
    </ul>
    <h2><HashLink id="decision" to="/typescript-errors#decision" mdxType="HashLink">{`What we decided`}</HashLink></h2>
    <p>{`After thinking about this for a while I submitted a small proposal PR to type check only our staged files before we could successfully commit them.`}</p>
    <p>{`This way, we could incrementally pay down the tech debt as we pushed the product forward. We would ensure that no new type errors were introduced in the files we were modifying without the manual overhead of managing file paths in a `}<inlineCode parentName="p">{`tsconfig`}</inlineCode>{`.`}</p>
    <p>{`As we shipped features and squashed bugs we were leaving the campground cleaner than we found it.`}</p>
    <h3><HashLink id="setup" to="/typescript-errors#setup" mdxType="HashLink">{`How to set this up`}</HashLink></h3>
    <p>{`To accomplish this we used `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/lint-staged"
      }}>{`the `}<inlineCode parentName="a">{`lint-staged`}</inlineCode>{` NPM package`}</a>{` to run linter checks only on our staged files.`}</p>
    <p>{`We used `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/eslint-plugin-tsc"
      }}>{`the `}<inlineCode parentName="a">{`eslint-plugin-tsc`}</inlineCode>{` NPM package`}</a>{` to wrap our TypeScript compiler checks in a linter rule.`}</p>
    <p>{`We enforced the linter rule on our staged files by configuring `}<inlineCode parentName="p">{`lint-staged`}</inlineCode>{` in our `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file as well as configuring our pre-commit hook to run `}<inlineCode parentName="p">{`lint-staged`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
...
    "lint-staged": {
        "src/**/*.{ts,tsx}": "eslint --plugin tsc --rule 'tsc/config: [2, {configFile: \\"./tsconfig.json\\"}]'"
    }
...
}
`}</code></pre>
    <p>{`We used `}<a parentName="p" {...{
        "href": "https://github.com/typicode/husky"
      }}>{`husky`}</a>{` to invoke pre-commit hooks. In our `}<inlineCode parentName="p">{`.husky/pre-commit`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#!/bin/sh
. "$(dirname "$0")/_/husky.sh"

npx lint-staged
`}</code></pre>
    <p>{`This way, only staged files were checked for TypeScript errors when committing code. A type error detected in the staged files would prevent the staged code from being committed.`}</p>
    <h3><HashLink id="downsides" to="/typescript-errors#downsides" mdxType="HashLink">{`Nothing is perfect, what gives?`}</HashLink></h3>
    <p>{`Our strategy was not without downsides.`}</p>
    <h4><HashLink id="breaking" to="/typescript-errors#breaking" mdxType="HashLink">{`Type fixes could break other types`}</HashLink></h4>
    <p>{`We had no automated checks to know when the type fixes in one file caused new type errors in other files.`}</p>
    <p>{`In retrospect we could've written a CI script to compare the number of typescript errors before the changes to the number of errors after the changes.`}</p>
    <p>{`Then we could've failed the CI pipeline when new errors were introduced.`}</p>
    <h4><HashLink id="overhead" to="/typescript-errors#overhead" mdxType="HashLink">{`Time overhead`}</HashLink></h4>
    <p>{`Another point of friction was the overhead of developers addressing type errors in areas of files they weren't intending to edit. Time was taken away from their task to debug (oftentimes someone else's) type errors.`}</p>
    <p>{`To time-box this, we decided that if fixing type errors unrelated to our changes took over 30 minutes then the dev would create a Jira ticket to capture that work and skip the type checks for those files.`}</p>
    <p>{`This actually worked out nicely because after 30 minutes the developer usually had a decent understanding of the issue as well as a path forward. The details were top-of-mind when they were captured in the Jira ticket.`}</p>
    <p>{`We worked with Product to prioritize new tickets as they were written. This planning made the entire team aware of where our complex type errors lived.`}</p>
    <h2><HashLink id="results" to="/typescript-errors#results" mdxType="HashLink">{`The results`}</HashLink></h2>
    <p>{`Even considering the downsides, this strategy was the best path forward for our team.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={typeFixesGraph} alt="Graph of type errors dwindling over the course of 1 year and one month" width="100%" />
    </div>
    <br />
    <Table data={{
      header: ['Date', 'Type Error Count'],
      rows: [['11/30/21', '408'], ['12/30/21', '318'], ['1/30/22', '257'], ['2/30/22', '251'], ['3/30/22', '224'], ['4/30/22', '222'], ['5/30/22', '210'], ['6/30/22', '217'], ['7/30/22', '245'], ['8/30/22', '233'], ['9/30/22', '221'], ['10/30/22', '109'], ['11/30/22', '114'], ['12/30/22', '20'], ['1/3/23', '0']]
    }} mdxType="Table" />
    <p>{`For the most part, we consistently reduced the number of errors month over month (with the exception of 3 months).`}</p>
    <blockquote>
      <p parentName="blockquote">{`We had no automated checks to know when the type fixes in one file caused new type errors in other files.`}</p>
    </blockquote>
    <p>{`This is why you can see the number of type errors increase during periods of heavy development.`}</p>
    <p>{`As we were closing in on 100 total type errors in the source code, it was time to create a Jira ticket to fix the rest all at once. The final push also included the addition of an automated CI check to ensure no new code could be merged with type errors.`}</p>
    <p>{`The strategy we used may not be the best approach for all teams but it worked quite well for us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      